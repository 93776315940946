import React from "react"
import Layout from "../components/layout"
import LogPage from "../components/members/login"

const LoginPage = () => {
  return (
    <Layout>  
     <LogPage/>
    </Layout>
  )
}

export default LoginPage
